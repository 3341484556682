import { Accordion, Box, Button, Checkbox, Grid, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useState } from "react";
import { IconCheck, IconShoppingCartFilled, IconX } from "@tabler/icons-react";
import Cookies from "universal-cookie";
import { useStore } from "../../api";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
} & ModalProps;


export const AddOrder = ({data, ...props}: Props) => {
    const {name, price, deliveryPrice, quantity} = data
    const [loading, setLoading] = useState(false);
    const theme = useMantineTheme();
    //@ts-ignore
    const dataStore = useStore((state: any) => state?.store);
    const [checked, setChecked] = useState(false);

    const onSubmitForm = ({name, subCategories}: any) => {
        setLoading(true)

    }

    const closeModal = () => {
        props.onClose();
        setLoading(false)
    };
    

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color="gray" variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{"إلغاء"}</Button>
                        <Button disabled={!checked} color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="form_order">{"تاكيد"}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}} >
                <Grid gutter={20} justify="center">
                    <Grid.Col span={12} >
                        <Accordion
                            mt={20} variant="filled" defaultValue="1"
                            style={{background: theme.colors.gray[0]}}
                            styles={{
                                label: {
                                    fontWeight: 700,
                                    color: theme.colors.gray[7]
                                },
                                icon: {
                                    color: theme.colors.gray[5]
                                },
                                content: {
                                    padding: 0
                                }
                            }}
                        >
                            <Accordion.Item value="1" style={{background: theme.colors.gray[0]}}>
                                {/*@ts-ignore*/}
                                <Accordion.Control style={{borderBottom: `2px solid ${dataStore?.information?.backgroundColor || "#645cbb"}7d`}} icon={<IconShoppingCartFilled size={26} /> } >ملخص الطلب</Accordion.Control>
                                <Accordion.Panel p={10}>
                                    <Stack gap={0}>
                                        {/*@ts-ignore*/}
                                        <Group justify='space-between' style={{borderBottom: `2px dotted ${dataStore?.information?.backgroundColor || "#645cbb"}7d`}} py={10}>
                                            <Text flex={4} fw={700} c={"gray.7"} >{name}</Text>
                                            
                                            <Text flex={1} fw={700} c={"gray.7"} >
                                                {/*@ts-ignore*/}
                                                <Text span={true} size='xs' fw={700} c={theme.white} bg={dataStore?.information?.backgroundColor || "#645cbb"} p={2} >{quantity}×</Text> {" "}
                                                {parseFloat(price?.toFixed(2))} د.ج
                                            </Text>
                                        </Group>
                                        {/*@ts-ignore*/}
                                        <Group justify='space-between' align='center' style={{borderBottom: `2px dotted ${dataStore?.information?.backgroundColor || "#645cbb"}7d`}} py={10}>
                                            <Text flex={4} fw={700} c={"gray.7"} >سعر التوصيل</Text>
                                            <Box flex={1}>
                                                {data?.freeShipping
                                                    /*@ts-ignore*/
                                                    ? <Text fw={700} size='sm' maw={"fit-content"} c={theme.white} bg={dataStore?.information?.backgroundColor || "#645cbb"} p={5} >مجانا</Text>
                                                    /*@ts-ignore*/
                                                    : <Text fw={700} size='sm' maw={"fit-content"} c={theme.white} bg={dataStore?.information?.backgroundColor || "#645cbb"} p={5} >{deliveryPrice} د.ج</Text>
                                                }
                                            </Box>
                                        </Group>
                                        
                                        <Group justify='space-between' py={10}>
                                            <Text flex={4} fw={700} c={"gray.7"} >السعر الإجمالي</Text>
                                            
                                            <Box flex={1}>
                                                {/*@ts-ignore*/}
                                                <Text fw={700} c={dataStore?.information?.backgroundColor || "#645cbb"} >
                                                    {data?.freeShipping
                                                        ? Math.round((quantity * price) - ((quantity * price) / 100))
                                                        : Math.round((quantity * price) + deliveryPrice - ((quantity * price) / 100))
                                                    } د.ج
                                                </Text>
                                            </Box>
                                        </Group>
                                    </Stack>
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Grid.Col>
                    
                    <Grid.Col span={12} >
                        <Checkbox
                            label={"اتعهد باستلام الطلب وعدم ارجعه وتكليف الشركة خسائر التوصيل."}
                            fw={"bold"}
                            checked={checked}
                            onChange={(event) => setChecked(event.currentTarget.checked)}
                        />
                    </Grid.Col>
                </Grid>
            </Box>
        </Modal>
    );
};